import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import debounce from 'lodash.debounce';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import cx from 'classnames';

import FormikDropdown from 'common/Formik/FormikDropdown';
import FormikInput from 'common/Formik/FormikInput';
import FormikDatePicker from 'common/Formik/FormikDatePicker';
import Loader from 'common/Loader';
import CircularLoader from 'common/CircularLoader';
import Drawer from './Drawer';
import Modal from 'components/HR/PerformanceReviewDetails/Modals/Modal';

import { getBusinessPipelinesList } from 'store/businessPipelines/businessPipelines.thunk';
import { getEngagementModelList } from 'store/engagementModel/engagementModel.thunk';
import { getBusinessPaymentList } from 'store/businessPayments/businessPayments.thunk';
import { getProjectInitRequests } from 'store/projectInitRequests/projectInitRequests.thunk';
import { updateService, deleteService } from 'store/services/services.thunk';
import { AppState } from 'store/configureStore';

import { DATE_PICKER, SERVICE_TYPES, SERVICES_CURRENCY, QUANTITY } from 'constants/common';
import { BillingType } from 'models/interfaces/services.interface';
import { AddServiceModalProps, ServiceValues } from 'models/interfaces/services.interface';

import styles from '../../../components/modal.module.scss';
import drawerStyles from './styles.module.scss';

const ADD_SERVICE_SCHEMA = Yup.object({
  serviceType: Yup.string().required('Field required'),
  name: Yup.string().min(2, 'Less than 2 characters').max(128, 'Service Name is too long').required('Field required'),
  startDate: Yup.string().nullable().required('Field required'),
  endDate: Yup.string().when('repeat', {
    is: (repeat: boolean) => !repeat,
    then: Yup.string().required('Field required').nullable(),
    otherwise: Yup.string().nullable(),
  }),
  currency: Yup.object().required('Field required'),
  amount: Yup.string()
    .test('min-length', 'Field required', value => typeof value === 'string' && value.length >= 0)
    .test('max-length', 'Amount is too long', value => typeof value === 'string' && value.length <= 128)
    .matches(/^\d{1,128}(\.\d+)?$/, 'Invalid amount format')
    .required('Field required'),
  pipelineId: Yup.object().nullable().optional(),
  engagementModelId: Yup.object().nullable().optional(),
  billingTypeId: Yup.object().nullable().required('Field required'),
  paymentBasisId: Yup.object().nullable().optional(),
  quantity: Yup.number().nullable().required('Field required'),
  discount: Yup.number()
    .nullable()
    .typeError('Invalid discount format')
    .min(0, 'Discount cannot be negative')
    .max(100, 'Discount cannot be more than 100')
    .test(
      'decimal-places',
      'Discount can have up to 2 decimal places',
      value => value == null || /^\d+(\.\d{1,2})?$/.test(value.toString())
    )
    .optional(),
});



const EditServiceModal = ({
  onClose,
  callback,
  setOpenModal,
  currentService,
  editContract,
  isEditable,
}: AddServiceModalProps) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  
  const { contract, loading } = useSelector((state: AppState) => state.contractsReducer);
  const { pipelines } = useSelector((state: AppState) => state.businessPipelinesReducer);
  const { models } = useSelector((state: AppState) => state.engagementModelsReducer);
  const { payments } = useSelector((state: AppState) => state.businessPaymentsReducer);
  const { billingTypes } = useSelector((state: AppState) => state.businessBillingTypesReducer);
  const { items: services } = useSelector((state: AppState) => state.servicesReducer);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [amountInput, setAmountInput] = useState(currentService?.amount || '');
  const [discountInput, setDiscountInput] = useState(currentService?.discount || '');
  const [nameInput, setNameInput] = useState(currentService?.name || '');
  const [total, setTotal] = useState(currentService?.total || '');
  const [loadingTotal, setLoadingTotal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const amountRef = useRef(null);
  const discountRef = useRef(null);
  const nameRef = useRef(null);

  useEffect(() => {
    dispatch(getProjectInitRequests({ page: 1, size: 250 }));
  }, []);

  const onPipelinesSearch = useCallback(
    debounce((event: KeyboardEvent) => {
      dispatch(getBusinessPipelinesList({ page: 1, size: 50 }));
    }, 250),
    []
  );

  const onModelsSearch = useCallback(
    debounce((event: KeyboardEvent) => {
      dispatch(getEngagementModelList({ page: 1, size: 50 }));
    }, 250),
    []
  );

  const onPaymentsSearch = useCallback(
    debounce((event: KeyboardEvent) => {
      dispatch(getBusinessPaymentList({ page: 1, size: 50 }));
    }, 250),
    []
  );

  const handleDeleteClick = () => {
    setIsConfirmModalOpen(true);
  };

  const confirmDelete = async () => {
    setIsDeleting(true);
    if (currentService?.id) await dispatch(deleteService(currentService?.id));
    await onClose();
    setIsDeleting(false);
    await callback();
    setIsConfirmModalOpen(false);
  };
  
  const initialValues: ServiceValues = {
    serviceType: currentService?.serviceType || '',
    name: currentService?.name || '',
    startDate: currentService?.startDate ? format(new Date(currentService.startDate), 'MMM dd, yyyy') : '',
    endDate: currentService?.endDate ? format(new Date(currentService.endDate), 'MMM dd, yyyy') : '',
    currency: SERVICES_CURRENCY.find(c => c.name === currentService?.currency) || '',
    amount: currentService?.amount || '',
    pipelineId: currentService?.pipeline
      ? { id: currentService.pipeline.id, name: currentService.pipeline.name }
      : null,
    engagementModelId: currentService?.engagementModel
      ? { id: currentService.engagementModel.id, name: currentService.engagementModel.name }
      : null,
    billingTypeId: currentService?.billingType || 0,
    paymentBasisId: currentService?.paymentBasis
      ? { id: currentService.paymentBasis.id, name: currentService.paymentBasis.name }
      : null,
    quantity: currentService?.quantity || null,
    discount: currentService?.discount || null,
  };

  const onSubmit = async (values: ServiceValues) => {
    setIsEditing(true);
    const formattedValues = {
      pipelineId: values.pipelineId?.id || null,
      engagementModelId: values.engagementModelId?.id || null,
      paymentBasisId: values.paymentBasisId?.id || null,
      billingTypeId: (values.billingTypeId as BillingType)?.id || 0,
      amount: Number(values.amount),
      currency: String((values.currency as BillingType)?.name || ''),
      serviceType: values.serviceType,
      name: values.name,
      quantity: values.quantity,
      discount: Number(values.discount),
      startDate: values.startDate ? format(new Date(values.startDate), 'yyyy-MM-dd') : '',
      endDate: values.endDate ? format(new Date(values.endDate), 'yyyy-MM-dd') : '',
      contractId: Number(id),
    };

    const updateServiceAction = await dispatch(updateService({ id: Number(currentService?.id), values: formattedValues }));
    const updateServicePayload = await (updateServiceAction as any).payload;
    setTotal(updateServicePayload.total);  
  };

  const canDeleteService = (editContract: any): boolean => {
    switch (editContract.status) {
      case 'draft':
        return true;
      case 'legal paperwork':
        return true;
      case 'waiting for sign':
        return true;
      default:
        return false;
    }
  };

  return createPortal(
    <Formik
      initialValues={initialValues}
      validationSchema={ADD_SERVICE_SCHEMA}
      validateOnBlur={true}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, validateForm, setFieldTouched, isValid, dirty }) => (
        <Form>
          <Drawer
            className={cx(drawerStyles.drawer, drawerStyles.active, 'text-left')}
            title="Service Details"
            onClose={() => {
              onClose();
              if (isEditing) callback();
            }}
          >
            <div className={styles.inputWrap}>
              <div className="dropdown-filter">
                <div className={cx(drawerStyles.gap, 'd-flex')}>
                  <p className="label-wrapper">Service Type</p>
                  <p className={styles.required}>*</p>
                </div>
                <FormikDropdown
                  name="serviceType"
                  placeholder="Service Type"
                  className={styles.drop}
                  data={SERVICE_TYPES || []}
                  handleChange={async (value: any) => {
                    await setFieldValue('serviceType', value);
                    const name = `${value}${values.pipelineId ? '-' : ''}${
                      values.pipelineId ? values.pipelineId?.name : ''
                    }${values.engagementModelId ? '-' : ''}${
                      values.engagementModelId ? values.engagementModelId?.name : ''
                    }-${currentService?.name.slice(-1)}`;
                    await setNameInput(name);
                    await setFieldValue('name', name);
                    await validateForm();
                    await onSubmit({ ...values, serviceType: value, name });
                  }}
                  addFilter={true}
                  onDropdownClose={() => {
                    setTimeout(() => {
                      setFieldTouched('serviceType', true);
                    }, 0);
                  }}
                  readOnly={isEditable ? false : true}
                />
              </div>

              <div className={cx(drawerStyles.spaceBetween, 'filters-block')}>
                <div>
                  <div className={cx(drawerStyles.gap, 'd-flex')}>
                    <p className="label-wrapper">Start Date</p>
                    <p className={styles.required}>*</p>
                  </div>
                  <FormikDatePicker
                    name="startDate"
                    dateFormat={DATE_PICKER.dateFormatMonthAndDay}
                    placeholderText="yyyy/mm/dd"
                    className={isEditable ? styles.input : styles.inputReadOnly}
                    minDate={contract?.startDate ? new Date(contract?.startDate) : ''}
                    maxDate={
                      contract?.endDate ? (values.endDate ? new Date(values.endDate) : new Date(contract?.endDate)) : ''
                    }
                    showYearDropdown
                    onBlur={() => setFieldTouched('startDate', true)}
                    readOnly={isEditable ? false : true}
                    onChange={async e => {
                      setLoadingTotal(true);
                      await setFieldValue('startDate', format(new Date(e), 'yyyy-MM-dd'));
                      await validateForm();
                      await onSubmit({ ...values, startDate: format(new Date(e), 'yyyy-MM-dd') });
                      setLoadingTotal(false);
                    }}
                  />
                </div>

                <div>
                  <div className={cx(drawerStyles.gap, 'd-flex')}>
                    <p className="label-wrapper">End Date</p>
                    <p className={styles.required}>*</p>
                  </div>
                  <FormikDatePicker
                    name="endDate"
                    dateFormat={DATE_PICKER.dateFormatMonthAndDay}
                    placeholderText="yyyy/mm/dd"
                    className={isEditable ? styles.input : styles.inputReadOnly}
                    minDate={
                      contract?.startDate
                        ? values.startDate
                          ? new Date(values.startDate)
                          : new Date(contract?.startDate)
                        : ''
                    }
                    maxDate={contract?.endDate ? new Date(contract?.endDate) : ''}
                    showYearDropdown
                    onBlur={() => setFieldTouched('endDate', true)}
                    readOnly={isEditable ? false : true}
                    onChange={async e => {
                      setLoadingTotal(true);
                      await setFieldValue('endDate', format(new Date(e), 'yyyy-MM-dd'));
                      await validateForm();
                      await onSubmit({ ...values, endDate: format(new Date(e), 'yyyy-MM-dd') });
                      setLoadingTotal(false);
                    }}
                  />
                </div>
              </div>

              <div className={cx(drawerStyles.spaceBetween, 'filters-block')}>
                <div className="dropdown-filter" style={{ maxWidth: '181px' }}>
                  <p className="label-wrapper">Pipeline</p>
                  <FormikDropdown
                    name="pipelineId"
                    className={isEditable ? styles.clearIcon : styles.readDropdown}
                    placeholder="Pipeline"
                    data={pipelines || []}
                    handleChange={async (value: any) => {
                      await setFieldValue('pipelineId', value);
                      const name = `${values.serviceType}-${value.name}${values.engagementModelId ? '-' : ''}${
                        values.engagementModelId ? values.engagementModelId?.name : ''
                      }-${currentService?.name.slice(-1)}`;
                      await setNameInput(name);
                      await setFieldValue('name', name);
                      await validateForm();
                      await onSubmit({ ...values, pipelineId: value, name });
                    }}
                    onDropdownSearch={onPipelinesSearch}
                    textField="name"
                    addFilter={true}
                    clearValue={isEditable ? true : false}
                    clear={async () => {
                      await setFieldValue('pipelineId', null);
                      const name = `${values.serviceType}${values.engagementModelId ? '-' : ''}${
                        values.engagementModelId ? values.engagementModelId?.name : ''
                      }-${currentService?.name.slice(-1)}`;
                      await setNameInput(name);
                      await setFieldValue('name', name);
                      await validateForm();
                      await onSubmit({ ...values, pipelineId: null, name });
                    }}
                    readOnly={isEditable ? false : true}
                  />
                </div>

                <div className="dropdown-filter" style={{ maxWidth: '181px' }}>
                  <p className="label-wrapper">Engagement Model</p>
                  <FormikDropdown
                    name="engagementModelId"
                    className={isEditable ? styles.clearIcon : styles.readDropdown}
                    placeholder="Engagement Model"
                    data={models || []}
                    handleChange={async (value: any) => {
                      await setFieldValue('engagementModelId', value);
                      const name = `${values.serviceType}${values.pipelineId ? '-' : ''}${
                        values.pipelineId ? values.pipelineId?.name : ''
                      }-${value.name}-${currentService?.name.slice(-1)}`;
                      await setNameInput(name);
                      await setFieldValue('name', name);
                      await validateForm();
                      await onSubmit({ ...values, engagementModelId: value, name });
                    }}
                    onDropdownSearch={onModelsSearch}
                    textField="name"
                    addFilter={true}
                    clearValue={isEditable ? true : false}
                    clear={async () => {
                      await setFieldValue('engagementModelId', null);
                      const name = `${values.serviceType}${values.pipelineId ? '-' : ''}${
                        values.pipelineId ? values.pipelineId?.name : ''
                      }-${currentService?.name.slice(-1)}`;
                      await setNameInput(name);
                      await setFieldValue('name', name);
                      await validateForm();
                      await onSubmit({ ...values, engagementModelId: null, name });
                    }}
                    readOnly={isEditable ? false : true}
                  />
                </div>
              </div>

              <div className={cx(drawerStyles.spaceBetween, 'filters-block')}>
                <div className="dropdown-filter" style={{ maxWidth: '181px' }}>
                  <p className="label-wrapper">Payment Basis</p>
                  <FormikDropdown
                    name="paymentBasisId"
                    className={isEditable ? styles.clearIcon : styles.readDropdown}
                    placeholder="Payment Basis"
                    data={payments || []}
                    defaultValue=""
                    onDropdownSearch={onPaymentsSearch}
                    textField="name"
                    addFilter={true}
                    handleChange={async (value: any) => {
                      await setFieldValue('paymentBasisId', value);
                      await validateForm();
                      await onSubmit({ ...values, paymentBasisId: value });
                    }}
                    clear={async () => {
                      await setFieldValue('paymentBasisId', null);
                      await validateForm();
                      await onSubmit({ ...values, paymentBasisId: null });
                    }}
                    clearValue={isEditable ? true : false}
                    readOnly={isEditable ? false : true}
                  />
                </div>

                <div className="dropdown-filter" style={{ minWidth: '181px' }}>
                  <div className={cx(drawerStyles.gap, 'd-flex')}>
                    <p className="label-wrapper">Billing Type</p>
                    <p className={styles.required}>*</p>
                  </div>
                  <FormikDropdown
                    name="billingTypeId"
                    className={isEditable ? '' : styles.readDropdown}
                    placeholder="Billing Type"
                    data={billingTypes || []}
                    textField="name"
                    onDropdownClose={() => {
                      setTimeout(() => {
                        setFieldTouched('billingTypeId', true);
                      }, 0);
                    }}
                    handleChange={async (value: any) => {
                      setLoadingTotal(true);
                      await setFieldValue('billingTypeId', value);
                      await validateForm();
                      await onSubmit({ ...values, billingTypeId: value });
                      setLoadingTotal(false);
                    }}
                    readOnly={isEditable ? false : true}
                  />
                </div>
              </div>

              <div className={cx(drawerStyles.spaceBetween, 'filters-block')}>
                <div className="dropdown-filter" style={{ minWidth: '181px' }}>
                  <div className={cx(drawerStyles.gap, 'd-flex')}>
                    <p className="label-wrapper">Currency</p>
                    <p className={styles.required}>*</p>
                  </div>
                  <FormikDropdown
                    name="currency"
                    placeholder="Currency"
                    className={isEditable ? '' : styles.readDropdown}
                    data={SERVICES_CURRENCY || []}
                    textField="name"
                    onDropdownClose={() => {
                      setTimeout(() => {
                        setFieldTouched('currency', true);
                      }, 0);
                    }}
                    handleChange={async (value: any) => {
                      setLoadingTotal(true);
                      await setFieldValue('currency', value);
                      await validateForm();
                      await onSubmit({ ...values, currency: value });
                      setLoadingTotal(false);
                    }}
                    readOnly={isEditable ? false : true}
                  />
                </div>

                <div className="dropdown-filter" style={{ minWidth: '181px' }} ref={amountRef}>
                  <div className={cx(drawerStyles.gap, 'd-flex')}>
                    <p className="label-wrapper">Amount</p>
                    <p className={styles.required}>*</p>
                  </div>
                  <FormikInput
                    name="amount"
                    placeholder="Amount"
                    className={isEditable ? styles.input : styles.inputReadOnly}
                    readOnly={isEditable ? false : true}
                    onBlur={e => {
                      setFieldValue('amount', amountInput);
                      setFieldTouched('amount', true);
                    }}
                    onHandleKeyDown={e => {
                      if (e.code === 'Escape') {
                        setFieldValue('amount', amountInput);
                        setFieldTouched('amount', true);
                        if (amountRef.current) {
                          (amountRef.current as HTMLElement).querySelector('input')?.blur();
                        }
                      }
                    }}
                    onSubmit={async (value: any) => {
                      setLoadingTotal(true);
                      await setAmountInput(value);
                      await setFieldValue('amount', value);
                      await validateForm();
                      await onSubmit({ ...values, amount: value });
                      setLoadingTotal(false);
                    }}
                    inlineEdit={true}
                  />
                </div>
              </div>

              <div className={cx(drawerStyles.spaceBetween, 'filters-block')}>
                <div className="dropdown-filter" style={{ minWidth: '181px' }}>
                  <div className={cx(drawerStyles.gap, 'd-flex')}>
                    <p className="label-wrapper">Quantity</p>
                    <p className={styles.required}>*</p>
                  </div>
                  <FormikDropdown
                    name="quantity"
                    placeholder="Quantity"
                    className={isEditable ? '' : styles.readDropdown}
                    data={QUANTITY || []}
                    textField="name"
                    handleChange={async (value: any) => {
                      setLoadingTotal(true);
                      await setFieldValue('quantity', value);
                      await validateForm();
                      await onSubmit({ ...values, quantity: value });
                      setLoadingTotal(false);
                    }}
                    onDropdownClose={() => {
                      setTimeout(() => {
                        setFieldTouched('quantity', true);
                      }, 0);
                    }}
                    readOnly={isEditable ? false : true}
                  />
                </div>

                <div className="dropdown-filter" style={{ minWidth: '181px' }} ref={discountRef}>
                  <div className={cx(drawerStyles.gap, 'd-flex')}>
                    <p className="label-wrapper">Discount, %</p>
                  </div>
                  <FormikInput
                    name="discount"
                    placeholder="Discount"
                    className={isEditable ? styles.input : styles.inputReadOnly}
                    readOnly={isEditable ? false : true}
                    onBlur={e => {
                      setFieldValue('discount', discountInput);
                      setFieldTouched('discount', true);
                    }}
                    onHandleKeyDown={e => {
                      if (e.code === 'Escape') {
                        setFieldValue('discount', discountInput);
                        setFieldTouched('discount', true);
                        if (discountRef.current) {
                          (discountRef.current as HTMLElement).querySelector('input')?.blur();
                        }
                      }
                    }}
                    onSubmit={async (value: any) => {
                      setLoadingTotal(true);
                      await setDiscountInput(value);
                      await setFieldValue('discount', value);
                      await validateForm();
                      await onSubmit({ ...values, discount: value });
                      setLoadingTotal(false);
                    }}
                    inlineEdit={true}
                  />
                </div>
              </div>

              <div ref={nameRef}>
                <div className={cx(drawerStyles.gap, 'd-flex')}>
                  <p className="label-wrapper">Service Name</p>
                  <p className={styles.required}>*</p>
                </div>
                <FormikInput
                  name="name"
                  placeholder="Service Name"
                  className={isEditable ? styles.input : styles.inputReadOnly}
                  readOnly={isEditable ? false : true}
                  onBlur={e => {
                    setFieldValue('name', nameInput);
                    setFieldTouched('name', true);
                  }}
                  onHandleKeyDown={e => {
                    if (e.code === 'Escape') {
                      setFieldValue('name', nameInput);
                      setFieldTouched('name', true);
                      if (nameRef.current) {
                        (nameRef.current as HTMLElement).querySelector('input')?.blur();
                      }
                    }
                  }}
                  onSubmit={async (value: any) => {
                    await setNameInput(value);
                    await setFieldValue('name', value);
                    await validateForm();
                    await onSubmit({ ...values, name: value });
                  }}
                  inlineEdit={true}
                />
              </div>

              <div>
                <div className={cx(drawerStyles.total, 'd-flex')}>
                  <p className="label-wrapper" style={{ margin: 0 }}>
                    Total Amount:
                  </p>
                  {loadingTotal ? <CircularLoader size={21} /> : <p style={{ margin: 0 }}>{total}</p>}
                </div>
              </div>
            </div>
            {isSubmitting ? (
              <div style={{ marginTop: 60 }}>
                <Loader />
              </div>
            ) : (
              <div className={styles.buttonsWrap}>
                <button
                  type="button"
                  onClick={() => {
                    onClose();
                    if (isEditing) callback();
                  }}
                  className={styles.cancelBtn}
                >
                  Close
                </button>
                {canDeleteService(editContract) && isEditable && (
                  <button type="button" onClick={handleDeleteClick} className={styles.deleteBtn}>
                    Delete
                  </button>
                )}
              </div>
            )}
          </Drawer>
          {isConfirmModalOpen && (
            <Modal onClose={() => setIsConfirmModalOpen(false)} title="Confirm Deletion">
              <p>Are you sure you want to delete this service?</p>
              <div className={styles.modalButtonsWrap}>
                {isDeleting ? (
                  <div style={{ marginTop: 32 }}>
                    <Loader />
                  </div>
                ) : (
                  <>
                    <button
                      onClick={() => setIsConfirmModalOpen(false)}
                      className={styles.cancelBtn}
                      style={{ flex: 1 }}
                    >
                      Cancel
                    </button>
                    <button onClick={confirmDelete} className={styles.deleteBtn} style={{ flex: 1 }}>
                      Yes, Delete
                    </button>
                  </>
                )}
              </div>
            </Modal>
          )}
        </Form>
      )}
    </Formik>,
    document.body
  );
};

export default EditServiceModal;