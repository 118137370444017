import React, { FC } from 'react';
import styles from './CircularLoader.module.scss';

interface CircularLoaderProps {
  size?: number;
}

const CircularLoader: FC<CircularLoaderProps> = ({ size = 40 }) => (
  <div
    className={styles.circularLoader}
    style={{ width: size, height: size, borderWidth: size * 0.1 }}
  />
);

export default CircularLoader;
