import React, { FC, useRef, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { Input, Tooltip } from 'reactstrap';
import { InputType } from 'reactstrap/lib/Input';
import { useClickOutside } from 'hooks/useClickOutside';
import { checkIcon } from 'constants/icons';
import styles from '../Formik.module.scss';
import stylesModal from '../../../components/modal.module.scss';

type FormikInputT = {
  className?: string;
  errorClassName?: string;
  label?: string;
  placeholder?: string;
  name: string;
  disabled?: boolean;
  readOnly?: boolean;
  type?: InputType;
  inputNumerProps?: any;
  onSubmit?: (e?: any) => void;
  onBlur?: (e?: any) => void;
  autoFocus?: boolean;
  onHandleKeyDown?: (e?: any) => void;
  setCurrentlyEditing?: (value: string) => void;
  inlineEdit?: boolean;
};
const FormikInput: FC<FormikInputT> = ({
  className,
  errorClassName,
  label,
  inputNumerProps,
  onSubmit,
  onBlur,
  onHandleKeyDown,
  readOnly,
  autoFocus,
  setCurrentlyEditing,
  inlineEdit,
  ...props
}) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const { error, touched } = meta;

  const elementRef = useRef(null);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  if (onSubmit && setCurrentlyEditing) {
    const handleSubmit = () => {
      if (field.value !== meta.initialValue) {
        onSubmit();
      }
      setCurrentlyEditing('');
    };

    useClickOutside(elementRef, handleSubmit);
  }

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (e?: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (onBlur && e?.target) {
      onBlur(e);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (onHandleKeyDown) onHandleKeyDown(e);
  };

  return (
    <div
      className={className}
      style={{ position: 'relative' }}
      ref={elementRef}
      onMouseEnter={() => setOpenTooltip(true)}
      onMouseLeave={() => setOpenTooltip(false)}
    >
      {label && <p className="label-wrapper">{label}</p>}
      <Input
        {...field}
        {...props}
        {...inputNumerProps}
        className={error && touched ? (errorClassName ? errorClassName : stylesModal.error) : ''}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        autoFocus={autoFocus}
        readOnly={readOnly}
      />
      {inlineEdit && isFocused && (
        <button
          type="submit"
          onMouseDown={e => e.preventDefault()}
          onClick={event => {
            event.preventDefault();
            setFieldValue(field.name, field.value);
            if (onSubmit) onSubmit(field.value);
            if (elementRef.current) {
              (elementRef.current as HTMLElement).querySelector('input')?.blur();
            }
          }}
          className={stylesModal.button}
        >
          {checkIcon}
        </button>
      )}
      {error && touched ? (
        errorClassName ? (
          <Tooltip placement="bottom" isOpen={openTooltip} target={elementRef}>
            <div>{error}</div>
          </Tooltip>
        ) : (
          <div className={styles.inputError}>{error}</div>
        )
      ) : null}
    </div>
  );
};

export default FormikInput;
